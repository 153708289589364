import React from 'react'

const History = () => {
  return (
    <div >
        <div class="header-image-height"></div>
        <section className="container p-5 pt-3 gray-section my-5 border" id="history">  
          <h3 className="text-center mb-5">History</h3>
            <p>
              We are based in Sialkot city, Pakistan. Sialkot is renowned for being the second home of Tuttlingen, Germany, the Global Leader in Surgical Instrument Manufacturing, as most German instrument manufacturers outsource their production to Sialkot before distributing it globally. 
            </p>
            <br />
            <p>
              We are certified manufacturers of High-Quality Surgical Instruments of Self-Retaining Retractors, Bone Cutting Rongeurs and Fishing Instruments, Hunting Knifes and Kitchen Tweezers since 1930, now in our third generation in this line of instruments manufacturing for local and international markets.
            </p>
            <br />
            <p>
            Hayat Inox Med is a Self-owned company established in 2010. The company is inherited from my forefathers and Now I am the only Owner of the company. We are a certified manufacturer of Surgical Instruments specialized in Self-Retaining Retractors, Mouth Gags, Gelpi and Bone Cutting Rongeurs, Fishing Instruments, Hunting Knifes and Kitchen Tweezers etc. (Single Use &amp; Re-useable) used in General Surgery, Spinal and Orthopedic Surgery.
            </p>
            <br />
            <p>
              We have facilities featuring Steel Forging (imported from Germany and Japan), Laser Cutting, Milling Machine, Assembling, Fitting, Grinding, Laser Welding, TIG Welding, Heat Treatment/Hardness Tampering, Machine Polishing, Electro Polishing, Sand Blasting, Passivation, Final Finishing, Ultrasonic Cleaning, Laser Marking, Quality Inspection, Labeling/Packing and Delivery.
            </p>
            <br />
            <p>
              Our combination of workmanship &amp; craftsmanship has acquired an expertise in delivering exactly our valued customers' expectations. Our instruments are manufactured through an optimized process using specialized machinery and a team of highly-skilled technicians.
            </p>
            <p>Our satisfied, happy clients are our strength and certitude to keep moving towards innovation.<br/><br/>
            <b>Muhammad Waqar<br/>Sole Proprietor</b></p>
        </section>
    </div>
  )
}

export default History