
import img from "../images/fishing.jpeg"
import Product from "../Product"
const FishingInstruments = () => {
  const image = [
    {id:0,img:img},
    {id:1,img:img},
    {id:2,img:img},

  ]
  return (
    <>
    <Product title="Fishing Instruments" image = {image}/>
    </>
  )
}
export default FishingInstruments