import React from 'react'

const Product = ({title , image}) => {
  return (
    <div>
   <div class="catalogue-header-image-height">

</div>
    <div className="container bg-white border my-5 p-5" id="products">
  <h2 className="text-center pb-5">{title}</h2>
  <div className="row gy-5">
{
    image?.map((item,index)=>{
        return(
            <div className="col-sm-4 " id={index}>
            <a href="/products">
              <img src={item.img} className="img-fluid shadow" height={300} alt="hayatinoxmed" />
            </a>
          </div>
        )
    })
}

  </div>
   </div>
</div>
  )
}

export default Product