import React from 'react'

const Services = () => {
  return (
    <div> {/* content here */}
     <div class="services-header-image-height">

    </div>
    <div className="container bg-white my-5 mx-auto p-5" id="service">
      <h4>CNC Laser Cutting</h4>
      <p>We produce same as like German High-Quality stainless steel and customized Instruments of Self Retaining Retractors &amp; Rongeurs. As per sample piece and design/sketch from customer requirements/Demand.</p>
      <h4>Manual Milling Machine</h4>
      <p>We produce same as like German High-Quality stainless steel and customized Instruments of Self Retaining Retractors &amp; Rongeurs. As per sample piece and design/sketch from customer requirements/Demand.</p>
      <h4>Customize Instruments Dsesign</h4>
      <p>We have the ability to customize and modify instruments to fit individual needs. We also provide ongoing development of new products in cooperation with surgeons and healthcare professionals. <br />
        We produce same as like German High-Quality stainless steel and customized Instruments of Self Retaining Retractors &amp; Rongeurs. As per sample piece and design/sketch from customer requirements/Demand.
      </p>
      <h4>Repair &amp; Maintenance</h4>
      <p>We produce same as like German High-Quality stainless steel and customized Instruments of Self Retaining Retractors &amp; Rongeurs. As per sample piece and design/sketch from customer requirements/Demand.</p>
      <h4>Instrument Refurbishment</h4>
      <p>We produce same as like German High-Quality stainless steel and customized Instruments of Self Retaining Retractors &amp; Rongeurs. As per sample piece and design/sketch from customer requirements/Demand.</p>
    </div>
</div>
  )
}

export default Services