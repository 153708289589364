import React from 'react'

const Qaulity = () => {
  return (
      <div>
        <div className="header-image-height">
        </div>
        <section className="container p-5 pt-3 my-5 border" id="quality">
          <h3 className="text-center mb-5">Quality</h3>
          <p>Each instrument is the result of the manufacturing process with exact awareness of instrument specifications and industry important quality assurance. All our instruments carry a limited lifetime warranty and are made of steel forged in Pakistan and chromium steel alloy imported from Japan and Germany. Our company is ISO 9001:2016, ISO 13485:2008 and CE Mark- 93 / 42 / EEC quality certified and CE approved to ensure compliance with the standards defined in line with medical devices.</p>
          <br />
          <br />
          <div className="row gy-5 text-center ">
            <div id="a" className="col-12 col-sm-3 col-md-3 "><img src="./photos/c.jpeg" alt="hayatinoxmed 1" /></div>
            <div id="b" className="col-12 col-sm-3 col-md-3"><img src="./photos/about1.jpeg" alt="hayatinoxmed 2" /></div>
            <div id="c" className="col-12 col-sm-3 col-md-3 "><img src="./photos/about2.jpeg" alt="hayatinoxmed 3" /></div>
            <div id="d" className="col-12 col-sm-3 col-md-3 "><img src="./photos/about3.jpeg" alt="hayatinoxmed 4" /></div>
          </div>
        </section>
      </div>

  )
}

export default Qaulity