import Slider from '../../Header/Slider'
import ProductHomeSection from './ProductHomeSection'
import WelcomeHomeSection from './WelcomeHomeSection'

const Home = () => {
  return (
    <>
    <Slider />
   <WelcomeHomeSection />
   <ProductHomeSection />
    </>

  )
}

export default Home