import React from 'react'
import wel1 from "./images/wel1.jpg"
import wel2 from "./images/wel2.jpg"
import wel3 from "./images/wel3.jpg"
import wel4 from "./images/wel4.jpg"
import pakistanLogo from "./../../../images/pakistan-logo.png"
import AboutHomeSection from './AboutHomeSection'

const WelcomeHomeSection = () => {
  return (
    <>
      <section>
        <div className='container my-5 py-5 border border-light  bg-lightgray'>
          <div className='d-flex flex-column h-50 justify-content-center w-100 align-items-center text-center'>
            <h3 className='main-color'>Welcome</h3>
            <p className='w-75'>We are based in Sialkot city, Pakistan. Sialkot is renowned for being the second home of Tuttlingen, Germany, the Global Leader in Surgical Instrument Manufacturing, as most German instrument manufacturers outsource their production to Sialkot before distributing it globally.             
            </p>
            <img src={pakistanLogo} width={60} alt="Pakistan Flage" className=''/>
          </div>
          <div className="container-fluid py-5">
            <div className="row g-2">
            <div className="col-sm-3">
            <div className="card text-white border-0  rounded-0 hover" style={{backgroundColor: 'rgb(11, 117, 201)'}}>
              <img src="./photos/welico1.png" data-aos="zoom-in" data-aos-duration={2000} className=" shadow rounded-circle icon" alt="..." style={{position: 'absolute', bottom: 10, left: 20}} />
              <h4 className="text-white small icon-text">
                Instruments Forging</h4>
              <img src={wel1} data-aos="zoom-in" data-aos-duration={3000} height="400px" className="card-img shadow rounded-0" alt="..." />
            </div>
            </div>
            <div className="col-sm-3">
            <div className="card text-white border-0  rounded-0 hover" style={{backgroundColor: 'rgb(11, 117, 201)'}}>
              <img src="./photos/welico2.jpg" data-aos="zoom-in" data-aos-duration={2000} className=" shadow rounded-circle icon" alt="..." />
              <h4 className="text-white small icon-text">CNC
                Milling</h4>
              <img src={wel2} data-aos="zoom-in" data-aos-duration={3000} height="400px" className="card-img shadow  rounded-0" alt="..." />
            </div>
            </div>
            <div className="col-sm-3">
            <div className="card  text-white border-0  rounded-0 hover" style={{backgroundColor: 'rgb(11, 117, 201)'}}>
              <img src="./photos/welico3.jpg" data-aos="zoom-in" data-aos-duration={2000} className="shadow rounded-circle icon" alt="..." />
              <h4 className="text-white small icon-text">
                Granding Machining</h4>
              <img src={wel3} data-aos="zoom-in" data-aos-duration={3000} height="400px" className="card-img shadow  rounded-0" alt="..." />
            </div>
            </div>
            <div className="col-sm-3">
            <div className="card text-white border-0  rounded-0 hover" style={{backgroundColor: 'rgb(11, 117, 201)'}}>
              <img src="./photos/welico4.jpg" data-aos="zoom-in" data-aos-duration={2000} className=" shadow rounded-circle icon" alt="..." />
              <h4 className="text-white small icon-text">Tig
                Welding</h4>
              <img src={wel4} data-aos="zoom-in" data-aos-duration={3000} height="400px" className="card-img shadow  rounded-0" alt="..." />
            </div>
            </div>
            </div>
          </div>
          <AboutHomeSection />
        </div>
      </section>
    </>
  )
}

export default WelcomeHomeSection