import React from 'react'

const Contact = () => {
  return (
    <div>
      <div className="contact-banner">
      </div>
      <div className="container gray-section my-5 p-4 py-5" id="contact">
        <h3 className="text-center mb-5"><b>Contact</b></h3>
        <form action="/contact/" method="post">
          <input type="hidden" name="csrfmiddlewaretoken" />
          <div className="row">
            <div className="col-sm-8 offset-sm-2">
              <div className="row">
                <div className="col-sm-6">
                  <label htmlFor="name">Name</label>
                  <input type="text" required className="form-control" name="name" id="name" />
                </div>
                <div className="col-sm-6">
                  <label htmlFor="email">Email</label>
                  <input type="email required" name="email" id="email" className="form-control" /> 
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <label htmlFor="subject">Subject</label>
                  <input type="text" required name="subject" id="subject" className="form-control" />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <label htmlFor="message">Message</label>
                  <textarea name="message" id="message" cols={30} rows={4} className="form-control" defaultValue={""} />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-sm-4">
                  <input type="submit" className="btn btn-primary  my-2" defaultValue="Send" />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Contact