import React from 'react'
import hunting from "../../../catalogues/hunting knife pdf (1).pdf"
import tweeezers from "../../../catalogues/HAYAT TWEEZERS.pdf.pdf"
import fishing from "../../../catalogues/fishing tools pdf (3).pdf"
import retractor from "../../../catalogues/Retractor.pdf"
import Retractor from '../../../catalogues/Retractor Pdf.pdf'
import huntingimg from "./images/1.jpeg"
import fishingimg from "./images/2.jpeg"
import tweeezersImg from "./images/3.jpeg"
import CatalogueList from './CatalogueList'
import retractorimg from "../../../catalogues/images/Retractor.jpeg"
const Catalogue = () => {

  const catalogues = [
    {id:0,title:"Self-Retaining Retractor for Surgery",pdfpath:Retractor , imgPath:retractorimg},
    {id:1,title:"Hunting Knife",pdfpath:hunting , imgPath:huntingimg},
    {id:2,title:"Fishing Instruments",pdfpath:fishing , imgPath:fishingimg},
    {id:3,title:"Kichen Tweezers",pdfpath:tweeezers , imgPath:tweeezersImg},
    {id:4,title:"Self-Retaining Retractors",pdfpath:retractor , imgPath:retractorimg},

  ]

  return (
    <>
      <CatalogueList catalogues={catalogues}/>
    </>

  )
}

export default Catalogue