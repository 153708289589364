import React from 'react'

import Product from "../Product"
import img from "../../Home/images/p1.jpeg"
const SelfRetainingRetractor = () => {
  const image = [
    {id:0,img:img},
    {id:1,img:img},
    {id:2,img:img},

  ]
  return (
    <>
    <Product title="Self-Retaining Retractor" image = {image}/>
    </>
  )
}

export default SelfRetainingRetractor