import React from 'react'

const TermAndCondition = () => {
  return (
    <div>
      <div class="header-image-height">

      </div>  <section className="container p-5 pt-3 my-5 bg-white border" id="term">
        <h3 className="text-center mb-5">Terms and Conditions</h3>
        <p>Hayat Inox Med respects all the cultures of the world and rejoices in the diversity of humanity, but we hold certain principals to be inherently universal. As part of our commitment to this policy and the continual development of our quality management we give a very high priority to the moral and ethical values of business.</p>
        <h3 className="text-start mb-5">Ordering:</h3>
        <p>In order to avoid any issues, please be sure to mention required product, exact quantity and exact Billing and Shipping Address. All product prices are in F.O.B. customs brokerage fees and duty, if applicable, become the responsibility of the customer. Customer takes legal possession as soon as merchandise is delivered to a common carrier.</p>
        <h3 className="text-start mb-5">Guarantee:</h3>
        <p>Hayat Inox Med Instruments are guaranteed against manufacturing defects either of material or workmanship. This guarantee is void if instruments are not maintained or repaired properly, or if they are not used for their intended surgical purpose. Weitlaner Retractor, Gelpi, Mouth Gag, Bone Cutting Rongeurs and Tungsten Carbide inserts on Needle Holders are guaranteed use for 5 years. You can sterilize and reuse the instruments again and again.
        </p><p>Any defective instrument will be repaired at no charge at the sole discretion of Hayat Inox Med.</p>
        <p>Repair and Maintenance:- A full line of repair and maintenance services is available from Hayat Inox Med. Repair forms are available by contacting customer service at sales@hayatinoxmed.com.</p>
        <p>Limitations:– Repair, alterations or modifications of any instrument by persons not authorized by Hayat Inox Med or abuse of instruments (physical or chemical) will result in immediate loss of guarantee.</p>
        <h3 className="text-start mb-5">Payment Options:</h3>
        <p>You can pay by:
          TT Bank Transfer, Western Union, Moneygram, Express Money and Ria Money Transfer. </p>
        <ol>
          1. Must 50% Advance for TT Bank Transfer.</ol>
        <ol>2. Must 100% Advance for Western Union, Moneygram, Express Money and Ria Money Transfer.</ol>
        <p>You can complete your payment and your order will be placed. You will receive Invoice and Confirmation of your order by email or courier (DHL/UPS/FedEx) as soon as you make payment.</p>
        <h3 className="text-start mb-5">Shipping and Handling:</h3>
        <p>We will ship your order by courier, air cargo and by sea and issue a tracking number by email. Your order will be delivered at your designated place safely and easily.</p>
        <p>NOTE :- Custom Clearance is not our responsibility for all international shipments, require that the consignee be responsible for all Customs Duty charges, Brokerage Fees and Local Taxes.</p>
        <h3 className="text-start mb-5">Return and Credits Policy:</h3>
        <p>To return any product please email us at sales@hayatinoxmed.com. Email us your invoice number and complete description about what exactly is the cause of return.</p>
        <p>
        </p><li>Return shipping, insurance, and handling is the responsibility of the customer.</li>
        <li>Credits will not be issued until the product is received in acceptable condition.</li>
        <li>Once the item has been used it is not returnable.</li>
        <li>Any claim for incorrect shipment, faulty or damaged goods on delivery, must be made within ten (10) working days of receipt of goods.</li>
        <li>Custom products are not eligible for return or exchange under any circumstances.</li>
        <li>Product will only be accepted for exchange/credit if received in sell-able condition.</li>
        <li>Product must be returned in the original sealed package to be accepted for replacement or credit.</li>
        <li>A restocking fee will apply to any product returned for reasons other than defect or damage.</li>
        <p />
        <p>The Restocking Fee Schedule is as follows:- </p>
        <p>
        </p><li>20% for items returned within 15 days</li>
        <li>30% for items returned between 15-45 days</li>
        <li>No credit after 45 days</li>
        <li>Shipping fees will not be credited.</li>
        <p />
        <h3 className="text-start mb-5">Order Cancellation:</h3>
        <p>Orders can only be cancelled in a period of 24 hours after making the payment, once we start production or preparation of your order the orders cannot be cancelled and there will be no refund for cancelling an order after 24 hours without a valid reason. If there is any problem in the products after receiving the delivery of your order then we do issue replacements free of cost and make the customer happy and satisfied.</p>
      </section>
    </div>
  )
}

export default TermAndCondition