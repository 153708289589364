import React from 'react'
import Home from './Components/Pages/Home/Home'

import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Header from './Components/Header/Header';
import Qaulity from './Components/Pages/About/Qaulity';
import History from './Components/Pages/About/History';
import TermAndCondition from './Components/Pages/About/TermAndCondition';
import BoneCuttingRongeur from './Components/Pages/Products/BoneCuttingRongeur/BoneCuttingRongeur';
import SelfRetainingRetractor from './Components/Pages/Products/SelfRetainingRetractor/SelfRetainingRetractor';
import FishingInstruments from './Components/Pages/Products/FishingInstruments/FishingInstruments';
import HuntingKnife from './Components/Pages/Products/HuntingKnife/HuntingKnife';
import KitchenTweezers from './Components/Pages/Products/KitchenTweezers/KitchenTweezers';
import Catalogue from './Components/Pages/Catalogue/Catalogue';
import Services from './Components/Pages/Services/Services';
import Contact from './Components/Pages/Contact/Contact';
import Footer from './Components/Footer/Footer';

const App = () => {
  return (
    <BrowserRouter>
    <Header />
    <Routes>
      <Route  path="/" element={<Home />} />
      <Route path="/Catalogue" element={<Catalogue/>}/>
      <Route path="/Services" element={<Services/>}/>
      <Route path="/Contact" element={<Contact />}/>
      
      <Route path="/qaulity" element={<Qaulity />} />
      <Route path="/history" element={<History />} />
      <Route path="/term&condition" element={<TermAndCondition />}/>
      
      <Route path="BoneCuttingRongeur" element={<BoneCuttingRongeur/>}/>
      <Route path="SelfRetainingRetractor" element={<SelfRetainingRetractor/>}/>
      <Route path='FishingInstruments' element={<FishingInstruments/>}/>
      <Route path="HuntingKnife" element={<HuntingKnife/>}/>
      <Route path="KitchenTweezers"  element={<KitchenTweezers/>}/>
    </Routes>
    <Footer/>
    </BrowserRouter>
  )
}

export default App