// import Swiper core and required modules
import { Navigation, Autoplay,Pagination, A11y } from 'swiper';
import { EffectFade } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';
import s1 from "../../images/slider/1.jpg"
import s2 from "../../images/slider/2.jpg"
import s3 from "../../images/slider/3.jpeg"
import s4 from "../../images/slider/4.JPG"
import s5 from "../../images/slider/5.jpeg"
import s6 from "../../images/slider/6.jpg"



// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import "./slider.css"
import 'swiper/css/effect-fade';

const Slider = () => {
  return (
    <Swiper    
      // install Swiper modules
      modules={[Autoplay,Navigation, Pagination, EffectFade, A11y]}
      slidesPerView={1}
      loop
      navigation
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
      onSwiper={(swiper) => console.log(swiper)} effect="fade"
      autoplay={{ delay: 3000}}

    >
  
      <SwiperSlide>
        <img src={s1} height="600px" width="100%" alt="Hayatinoxmed Slide1" className='slider-image'/>
      </SwiperSlide>
      <SwiperSlide>
      <img src={s2} height="600px" width="100%" alt="Hayatinoxmed Slide2" className='slider-image'/>
      </SwiperSlide>
      <SwiperSlide>
      <img src={s3} height="600px" width="100%" alt="Hayatinoxmed Slide3" className='slider-image'/>
      </SwiperSlide>
      <SwiperSlide>
      <img src={s4} height="600px" width="100%" alt="Hayatinoxmed Slide4" className='slider-image'/>
      </SwiperSlide>
      <SwiperSlide>
      <img src={s5} height="600px" width="100%" alt="Hayatinoxmed Slide5" className='slider-image'/>
      </SwiperSlide>
      <SwiperSlide>
      <img src={s6} height="600px" width="100%" alt="Hayatinoxmed Slide5" className='slider-image'/>
      </SwiperSlide>

    </Swiper>
  );
};

export default Slider;