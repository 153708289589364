import React from 'react'
const Footer = () => {
  return (
    <>
    
<footer>
  <div className="container-fluid bg-lightgray  fs-6">
    <div className="row">
      <div className="col-sm-6 ">
      
      
        <div className="container-fluid ">
          <div className="row">
       
            <div className="col-sm-11 offset-sm-1 ">
              <address className="fs-6 mt-3">
                <i className="far fa-map-marker-check">&nbsp;&nbsp; 
                </i>
                Street # 32,Hayat Building,<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;New Miana Pura West Roras Road, 
                 <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Sialkot-51310-Pakistan.
              </address>
              <address className="text-start fs-6">

                  <i className="far fa-phone">&nbsp;&nbsp;</i><span>+92-52561850</span> <br />
                  <i className="fal fa-mobile"></i>&nbsp;&nbsp;&nbsp;&nbsp;+92-3338641849  <br />
                  <i className="fal fa-envelope"> </i>&nbsp;&nbsp;sales@hayatinoxmed.com<br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;hayatinoxmed@gmail.com<br />
                  <i className="fal fa-globe"></i> &nbsp;&nbsp;www.hayatinoxmed.com<br />
  
              </address>
            </div>
            <div className="col-sm-11 col-sm-11 offset-sm-1">
              <a href="https://www.facebook.com" className><i className="fab fa-facebook mx-3 fa-1x" /></a>
              <a href="https://www.instagram.com"><i className="fab fa-instagram fa-1x mx-3" /></a>
              {/* add whatspp */}
              <a href="https://wa.me/923338641849"><i className="fab fa-whatsapp fa-1x mx-3" /></a>
              <a href="https://www.linkedin.com/feed/"><i className="fab fa-linkedin fa-1x mx-3" /></a>
            </div>
          </div>
        </div>
      </div>
      <div className="col-sm-6">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6730.550198101579!2d74.50986597329553!3d32.492075674235764!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391eea1809f39c5b%3A0xd2c4881e9d15ddc1!2sHayat%20Inox%20Med!5e0!3m2!1sen!2sus!4v1628677516871!5m2!1sen!2sus" width="100%" height="100%" style={{border: 0}} allowFullScreen loading="lazy" title='hayatinoxmed' />
      </div>
    </div>
    <div className="row" style={{backgroundColor: '#eee'}}>
      <hr />
      <div className="col-sm-6">
        <a className="nav-link" href="404.html">
          <i className="fas fa-user-cog" /> Terms &amp; Conditions Privacy</a>
      </div>
      <div className="col-sm-6 text-end">
        <p className="italic">
          Copyright © 2010-2023 Hayat Inox Med.
          All rights reserved
        </p>
      </div>
    </div>
  </div>
</footer>

    </>
  )
}

export default Footer