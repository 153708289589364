import React from 'react'
import "./header.css"
import logo from "../../images/logo.png"
import {NavLink } from "react-router-dom";


const Header = () => {

  // const [home , setHome] = useState("Home")
  // const [about , setAbout] = useState("About")
  // const [products , setProducts] = useState("Products")
  // const [catalogue , setCatalogue] = useState("Catalogue")
  // const [services , setServices] = useState("Services")
  // const [contact , setContact] = useState("Contact")
  

  return (
    <>
<nav className="navbar navbar-expand-lg  sticky-top" id="nav">
  <div className="container">
    <NavLink className="navbar-brand" to="/">
        <img src={logo} alt="Hayatinoxmed Logo" width={150}/>
    </NavLink>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon" />
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
        <li className="nav-item">
          <NavLink className="nav-link"  to="/">Home</NavLink>
        </li>
        <li className="nav-item dropdown ">
          <NavLink className="nav-link dropdown-toggle" data-bs-toggle="dropdown">
            About
          </NavLink>
          <ul className="dropdown-menu dropdown-menu-end">
            <div className='raper'>
              <li><NavLink className="dropdown-item" to="/qaulity">Quality</NavLink></li>
              <li><NavLink className="dropdown-item" to="/history">History</NavLink></li>
              <li><NavLink className="dropdown-item" to="/term&condition">Terms & Condition</NavLink></li>
            </div>
          </ul>
        </li>
        <li className="nav-item dropdown">
          <NavLink className="nav-link dropdown-toggle"   role="button" data-bs-toggle="dropdown">
            Products
          </NavLink>
          <ul className="dropdown-menu dropdown-menu-end">
            <div className='raper'>
            <li><NavLink className="dropdown-item" to="/SelfRetainingRetractor">Self-Retaining Retractor</NavLink></li>
            <li><NavLink className="dropdown-item" to="/BoneCuttingRongeur">Bone Cutting Rongeur</NavLink></li>
            <li><NavLink className="dropdown-item" to="/FishingInstruments">Fishing Instruments</NavLink></li>
            <li><NavLink className="dropdown-item" to="/HuntingKnife"> Hunting Knife</NavLink></li>
            <li><NavLink className="dropdown-item" to="/KitchenTweezers">Kitchen Tweezers</NavLink></li>

            </div>
          </ul>
        </li>

        <li className="nav-item">
          <NavLink className="nav-link" to="/Catalogue">Catalogue</NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link" to="/Services">Services</NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link" to="/Contact">Contact</NavLink>
        </li>
      </ul>
 
    </div>
  </div>
</nav>
    </>
  )
}

export default Header