import React from 'react'
import service from "./images/service.jpg"
const ServicesHomeSection = () => {
  return (
    <div className=''>
        <div className="container my-5 py-5 bg-lightgray">
            <div className="row">
            <div className="col-sm-6 justify-content-center border-0 d-flex align-items-center flex-column">
                <h4 style={{color: '#0570C1'}} className="text-center mt-4"><b>Genuine Manufacturer</b>
                </h4>
                <ul className='star'>
                <li className='mt-4'>
                    90+ Years of Expertise in Surgical Instruments especially Self-Retaining Retractors, Bone Cutting Rongeurs,Fishing Instruments, Hunting Knifes and Kitchen Tweezers.</li>
                <li className='mt-2 pt-2 '>
                    Ability to Identify and Refine Customer Requirements.
                </li>
                <li className='mt-2 pt-2 '>
                    Experienced Engineering Team available to Develop New Concepts, Collaborate, or
                    bring original IP to market.
                </li>
                <li className='mt-2 pt-2 '>
                    Manage Design control elements such as Inputs, Outputs, Verification and
                    Validation, 
                    Customization and Support. 
                </li>
                </ul>
            </div>
            <div className="col-sm-6  m-0">
                <div className="hover" style={{backgroundColor:'rgb(11, 117, 201)'}}>
                <img src={service}   width="100%" className="d-block h-100" alt="hayatinoxmed" />
                </div>
            </div>
            </div>
        </div>
    </div>
  )
}

export default ServicesHomeSection