import React from 'react'
import about from "./images/about.jpg"
const AboutHomeSection = () => {
  return (
    <div>
         <div class="container-fluid">
          <div class="row p-2">
              <div class="col-sm-6  border-0 d-flex justify-content-start align-items-center">
                  <p class=" border-0 text-center">
                  We are Certified Manufacturers of High-Quality Surgical Instruments of Self-Retaining Retractors, Bone Cutting Rongeurs, Fishing Instruments, Hunting Knifes and Kitchen Tweezers since 1930, Now in our Third Generation in this line of Instruments Manufacturing for local and International Markets
                      <br />
                      <a href="/history" class="btn btn-primary rounded-0 text-center mt-4">About
                          More</a>
                  </p>
              </div>
              <div class="col-sm-6 m-0 p-2">
                  <div>
                      <img src={about}  width="100%" height="100%" class="d-block h-100" alt="" />
                  </div>
              </div>
          </div>
      </div>
    </div>
  )
}

export default AboutHomeSection