import React from 'react'
import Product from '../Product'

import img from "../images/p2.jpg"
const BoneCuttingRongeur = () => {
  const image = [
    {id:0,img:img},
    {id:1,img:img},
    {id:2,img:img},

  ]
  return (
    <div>
      <Product title="Bone Cutting Rongeur" image = {image}/>
    </div>
  )
}

export default BoneCuttingRongeur