import React from 'react'

const CatalogueList = ({catalogues}) => {
    console.log(catalogues)

  return (
    <div>
         <div class="catalogue-header-image-height">

</div>
        <section className="container my-5 bg-white" id="catalogue">
        <div className="row">
            {
                catalogues?.map((item,index)=>{
                    return(
                        <div className="col-12 col-sm-4 m-0 p-0 col-md-4 col-xl-4 text-center  border" id={index}>
                            <a href={item.pdfpath} download className='btn btn-primary my-3 rounded-0'>
                            <h4 className='text-center '> {item.title}</h4>
                            </a>
                            <a href={item.pdfpath} download >
                            <img className="w-100" src={item.imgPath} height="400px" alt="hayatinoxmed 1" />
                            </a>
                        </div>
                    )
                })
            }
            
        </div>    
</section>
    </div>
  )
}

export default CatalogueList