import React from 'react'

import Product from "../Product"

import img from ".././images/kitchen.jpg"
const KitchenTweezers = () => {
  const image = [
    {id:0,img:img},
    {id:1,img:img},
    {id:2,img:img},

  ]
  return (
    <>
    <Product title="Kitchen Tweezers" image = {image}/>
    </>
  )
}

// export default SelfRetainingRetractor
export default KitchenTweezers