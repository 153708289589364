import React from 'react'
import p1 from "./images/p1.jpeg"
import p2 from "./images/p2.jpg"
import p3 from "./images/p3.jpeg"
import p4 from "./images/p4.jpg"

import ServicesHomeSection from './ServicesHomeSection'
const ProductHomeSection = () => {
  return (
    <>
<section className="container bg-white py-5 border rounded-0 my-5">
  <div className="container-fluid p-1">
    <div className="row">
      <div className="col-sm-4 d-flex flex-column  justify-content-between">
        <a href="/SelfRetainingRetractor">
          <button className=" btn rounded-0 btn-outline-primary fs-4  button">
            Self-Retaining Retractor
          </button>
        </a>
        <a href="/BoneCuttingRongeur">
          <button className=" btn rounded-0 btn-outline-primary fs-4 button">
            Bone Cutting Rongeurs
          </button>
        </a>
        <a href="/FishingInstruments">
          <button className=" btn rounded-0 btn-outline-primary fs-4  button">
          Fishing Instruments
          </button>
        </a>
        <a href="/HuntingKnife">
          <button className=" btn rounded-0 btn-outline-primary fs-4  button">
          Hunting Knife
          </button>
        </a>
        <a href="/KitchenTweezers">
          <button className=" btn rounded-0 btn-outline-primary fs-4 button">
          Kitchen Tweezers
          </button>
        </a>
      </div>
      <div className="col-sm-8">
        <div className="container-fluid">
          <div className="row g-2">
            <div className="col-sm-6">
              <a href="/SelfRetainingRetractor">
                <div className="border-0  img  rounded-0">
                  <img src={p1}   className="card-img rounded-0 border" height="300px" alt="..." />
                </div>
              </a>
            </div>
            <div className="col-sm-6">
              <a href="/BoneCuttingRongeur">
                <div className=" border-0  rounded-0">
                  <img src={p2}   className="card-img rounded-0 border" height="300px" width="100%" alt="..." />
                </div>
              </a>
            </div>
          </div>
          <div className="row g-2 mt-2">
            <div className="col-sm-6">
              <a href="/HuntingKnife">
                <div className="border-0 img  rounded-0">
                  <img src={p3}    className="card-img rounded-0 border scale" height="300px" width="100%" alt="..." />
                </div>
              </a>
            </div>
            <div className="col-sm-6">
              <a href="/KitchenTweezers">
                <div className="border-0 img rounded-0 ">
                  <img src={p4}   className="card-img rounded-0 border" height="300px" alt="..." />
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</section>
<ServicesHomeSection />
    </>
  )
}

export default ProductHomeSection