
import img from ".././images/hunting.jpeg"
import Product from "../Product"
const HuntingKnife = () => {
  const image = [
    {id:0,img:img},
    {id:1,img:img},
    {id:2,img:img},

  ]
  return (
    <>
    <Product title="Hunting Knife" image = {image}/>
    </>
  )
}
export default HuntingKnife